<template>
  <div v-if="loggedIn">
    <AppHeader />
    <AppDashboard v-if="account" v-bind:account="account" />
  </div>
  <AppLogin v-else />
</template>

<script>
import { PublicClientApplication } from "@azure/msal-browser";
import AppHeader from "@/components/AppHeader.vue";
import AppDashboard from "@/components/AppDashboard.vue";
import AppLogin from "@/components/AppLogin.vue";

export default {
  name: "HomeView",
  components: {
    AppHeader,
    AppDashboard,
    AppLogin,
  },
  data() {
    return {
      account: undefined,
      loggedIn: false,
    };
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
    await this.$msalInstance.initialize();

    this.$emitter.on("login", (account) => {
      this.account = account;
      this.loggedIn = true;
    });

    this.$emitter.on("logout", () => {
      this.account = undefined;
      this.loggedIn = false;
    });
  },
  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (!accounts.length) return;
    this.loggedIn = true;
    this.account = accounts[0];
  },
};
</script>
