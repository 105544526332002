<template>
  <div>
    <h3 style="display: inline">ProviderId</h3>
    <select v-model="selectedProvider" @change="providerChanged">
      <option v-for="provider in providers" :key="provider" :value="provider">
        {{ provider }}
      </option>
    </select>
  </div>
</template>

<script>
import AccessTokenService from "@/services/AccessToken.service";

export default {
  name: "AppDashboardProviders",
  data() {
    return {
      providers: {},
      selectedProvider: -1,
    };
  },
  mounted() {
    this.getProviders();
  },
  methods: {
    async getProviders() {
      this.isLoading = true;

      const url = `./api/me/providers`;
      const bearer = "Bearer " + (await AccessTokenService.getAccessToken());

      fetch(url, {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.isLoading = false;
            throw new Error("Something went wrong");
          }
        })
        .then((responseJson) => {
          this.providers = responseJson;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    providerChanged() {
      console.log("provider changed");
      this.$emit("providerChanged", this.selectedProvider);
    },
  },
};
</script>

<style scoped>
h3 {
  margin-bottom: 10px;
  margin-right: 10px;
}

select {
  margin-bottom: 20px;
}
</style>
