<template>
  <div v-if="isEditing" class="mobile-number-edit-wrapper">
    <div class="mobile-number-edit">
      <input
        type="tel"
        v-model="mobileNumber"
        :disabled="isSubmitting"
        ref="telInput"
        :class="isValid ? '' : 'invalid'"
        @keyup.enter="update()"
      />

      <div class="mobile-number-edit-controls">
        <button class="button-link text-small" @click="update">Save</button>
        <button class="button-link text-small" @click="cancel">Cancel</button>
      </div>
    </div>
    <div class="mobile-number-edit-toggles">
      <label
        >Archived:
        <AppSwitchControl
          v-bind:initial-state="isArchived"
          callbackName="isMobileArchived"
          id="mobile-number-isarchived-toggle"
      /></label>

      <label class="mobile-number-edit-isoptedout"
        >Opted out:
        <AppSwitchControl
          v-bind:initial-state="isOptedOut"
          callbackName="isMobileOptedOut"
          id="mobile-number-isoptedout-toggle"
      /></label>
    </div>
  </div>
  <div v-else class="mobile-number-wrapper">
    {{ mobileNumber }}
    <small v-show="updateSuccess || isNew" class="success-message">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="check"
        class="svg-inline--fa fa-check fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        ></path>
      </svg>
      {{ isNew ? "Added" : "Updated" }}
    </small>
    <div>
      <button
        class="button-link text-small"
        :disabled="!isValid || isSubmitting"
        @click="edit"
      >
        Edit
      </button>
      <button
        class="button-link text-small"
        :disabled="!isValid || isSubmitting"
        @click="deleteContact"
      >
        Delete
      </button>
    </div>
  </div>
</template>

<script>
import { UK_MOBILE_NUMBER_REGEX } from "./../constants";
import AppSwitchControl from "@/components/AppSwitchControl.vue";
import AccessTokenService from "@/services/AccessToken.service";
const SUCCESS_MESSAGE_DISPLAY_TIME_MS = 2000;

export default {
  name: "AppDashboardMobile",
  props: {
    mobile: Object,
    isNew: Boolean,
    sourceSystemId: {
      type: Number,
      required: true,
    },
  },
  components: {
    AppSwitchControl,
  },
  mounted() {
    this.$emitter.on("isMobileOptedOut", (val) => {
      this.isOptedOut = val;
    });
    this.$emitter.on("isMobileArchived", (val) => {
      this.isArchived = val;
    });
  },
  unmounted() {
    this.$emitter.off("isMobileOptedOut");
    this.$emitter.off("isMobileArchived");
  },
  data() {
    return {
      mobileNumber: this.mobile.line1,
      isArchived: this.mobile.isArchived,
      isOptedOut: this.mobile.isOptedOut,
      updateSuccess: false,
      isSubmitting: false,
      isValid: true,
      isEditing: false,
      isDeleted: false,
    };
  },
  watch: {
    mobileNumber(value) {
      this.mobileNumber = value;
      this.isValid = this.validateMobileNumber(value);
    },
  },
  methods: {
    validateMobileNumber(value) {
      return UK_MOBILE_NUMBER_REGEX.test(value);
    },
    edit() {
      this.isEditing = true;
      this.originalNumber = this.mobileNumber;

      this.$nextTick(() => this.$refs.telInput.focus());
    },
    cancel() {
      this.isEditing = false;
      this.mobileNumber = this.originalNumber;
    },
    async update() {
      if (!this.isValid) return;
      this.isSubmitting = true;
      const bearer = "Bearer " + (await AccessTokenService.getAccessToken());
      fetch(`./api/me/contact/${this.mobile.patientContactId}`, {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Line1: this.mobileNumber,
          ContactTypeId: 1,
          IsArchived: this.isArchived,
          IsOptedOut: this.isOptedOut,
          SourceSystemId: this.sourceSystemId,
        }),
      })
        .then((response) => {
          if (response.ok) {
            this.updateSuccess = true;
            this.isSubmitting = false;
            this.isEditing = false;

            setTimeout(() => {
              this.updateSuccess = false;
            }, SUCCESS_MESSAGE_DISPLAY_TIME_MS);
          } else {
            this.isSubmitting = false;

            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          this.isSubmitting = false;

          console.log(error);
        });
    },
    async deleteContact() {
      this.isSubmitting = true;
      const bearer = "Bearer " + (await AccessTokenService.getAccessToken());

      fetch(`./api/me/contact/${this.mobile.patientContactId}`, {
        method: "DELETE",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.isSubmitting = false;
          if (!response.ok) {
            throw new Error("Something went wrong");
          }

          this.$emitter.emit("mobileNumberDeleted", this.mobile);
          this.isEditing = false;
        })
        .catch((error) => {
          this.isSubmitting = false;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

.mobile-number-wrapper {
  display: flex;
  flex: 1;

  span {
    font-weight: 600;
    width: 250px;

    .theme-dark & {
      font-weight: 400;
    }
  }

  div {
    margin-left: auto;
    button {
      width: 50%;
    }
  }
}

.success-message {
  align-items: center;
  animation: FADE_OUT 0.5s 1.5s ease-out forwards;
  color: var(--success);
  display: flex;
  font-weight: 400;
  margin-left: $spacer;

  svg {
    margin-right: $spacer-1;
    width: 1em;
  }
}

.mobile-number-edit-wrapper {
  width: 100%;

  .mobile-number-edit {
    align-items: center;
    display: flex;
    flex: 1;
    input {
      margin-right: $spacer-2;
      width: 65%;
    }
  }
  .mobile-number-edit-toggles {
    width: 65%;
    padding-top: 5px;
    label {
      display: inline;
    }
  }
  .mobile-number-edit-isoptedout {
    float: right;
  }
}

.mobile-number-edit-controls {
  display: grid;
  grid-gap: 0 $spacer-3;
  grid-template-columns: auto auto;
  margin-left: auto;
}
</style>
