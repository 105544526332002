<template>
  <div v-if="isEditing">
    <div class="title-edit-wrapper">
      <input
        type="tel"
        v-model="localTitle"
        :disabled="isUpdating"
        ref="telInput"
        :class="invalid ? 'invalid' : ''"
        @keyup.enter="update()"
      />

      <div class="title-edit-controls">
        <button class="button-link text-small" @click="update">Save</button>
        <button class="button-link text-small" @click="cancel">Cancel</button>
      </div>
    </div>
    <div v-if="titleError !== ''">
      {{ titleError }}
    </div>
  </div>
  <p v-else class="title-wrapper">
    <span>{{ localTitle }}</span>
    <small v-show="updateSuccess" class="success-message">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="check"
        class="svg-inline--fa fa-check fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        ></path>
      </svg>
      Updated
    </small>
    <button class="button-link text-small" @click="edit">Edit</button>
  </p>
</template>

<script>
import AccessTokenService from "@/services/AccessToken.service";
const SUCCESS_MESSAGE_DISPLAY_TIME_MS = 2000;

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    sourceSystemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      isUpdating: false,
      updateSuccess: false,
      localTitle: this.title,
      invalid: false,
      titleError: "",
    };
  },
  watch: {
    localTitle(value) {
      this.localTitle = value;
      this.validateTitle(value);
    },
  },
  methods: {
    validateTitle(value) {
      if (value.length <= 50) {
        this.titleError = "";
      } else {
        this.invalid = true;
        this.titleError = "Title cannot be more than 50 characters";
      }
    },
    edit() {
      this.originalTitle = this.localTitle;
      this.isEditing = true;
    },
    cancel() {
      this.isEditing = false;
      this.localTitle = this.originalTitle;
    },
    async update() {
      if (this.invalid) return;

      const url = `./api/me/title/${this.sourceSystemId}`;
      const bearer = "Bearer " + (await AccessTokenService.getAccessToken());

      const data = {
        title: this.localTitle,
      };

      fetch(url, {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            this.updateSuccess = true;
            this.isUpdating = false;
            this.isEditing = false;

            setTimeout(() => {
              this.updateSuccess = false;
            }, SUCCESS_MESSAGE_DISPLAY_TIME_MS);
          } else {
            this.isUpdating = false;

            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          this.isUpdating = false;

          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

.title-wrapper {
  display: flex;
  flex: 1;

  span {
    font-weight: 600;

    .theme-dark & {
      font-weight: 400;
    }
  }

  button {
    margin-left: auto;
  }
}

.success-message {
  align-items: center;
  animation: FADE_OUT 0.5s 1.5s ease-out forwards;
  color: var(--success);
  display: flex;
  font-weight: 400;
  margin-left: $spacer;

  svg {
    margin-right: $spacer-1;
    width: 1em;
  }
}

.title-edit-wrapper {
  align-items: center;
  display: flex;
  flex: 1;

  input {
    margin-right: $spacer-2;
    width: 40%;
  }
}

.title-edit-controls {
  display: grid;
  grid-gap: 0 $spacer-3;
  grid-template-columns: auto auto;
  margin-left: auto;
}
</style>
