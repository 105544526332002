<template>
  <AppSwitchControl
    callback-name="onProfileActiveSwitchChange"
    id="profileSwitch"
    v-bind:initial-state="active"
    v-bind:toggleSourceSystemId="sourceSystemId"
  />
</template>
<script>
import AppSwitchControl from "@/components/AppSwitchControl.vue";
import AccessTokenService from "@/services/AccessToken.service";

export default {
  name: "AppToggleProfileActive",
  components: { AppSwitchControl },
  props: {
    id: String,
    active: Boolean,
    sourceSystemId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.$emitter.on(
      "onProfileActiveSwitchChange",
      (_, toggleSourceSystemId) => {
        if (toggleSourceSystemId === this.sourceSystemId) {
          this.update();
        }
      },
    );
  },
  unmounted() {
    this.$emitter.off("onProfileActiveSwitchChange");
  },
  methods: {
    async update() {
      const url = `./api/me/active/${this.sourceSystemId}`;
      const bearer = "Bearer " + (await AccessTokenService.getAccessToken());

      fetch(url, {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((responseJson) => {
          this.$emitter.emit("onProfileActiveToggled", responseJson.isArchived);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
</style>
