import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      msalConfig: {
        auth: {
          clientId: "04068b56-048f-42a0-9f22-be598d5b44f3",
          authority:
            "https://login.microsoftonline.com/c1c68196-2362-4d1f-989d-73fa5f33e293",
        },
        cache: {
          cacheLocation: "localStorage",
        },
      },
    };
  },
});

export default store;
