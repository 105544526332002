import { Service } from "./Service";
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

const REQUEST_SCOPE =
  "api://754045a5-108e-44ba-83bc-f4bbaf2bb7e9/access_as_user";

class AccessToken extends Service {
  constructor() {
    super();
  }

  async getAccessToken() {
    if (!this.$msalInstance) {
      await this.setup();
    }
    await this.$msalInstance.initialize();

    if (this.$msalInstance.getAllAccounts().length === 0) {
      this.$emitter.emit("logout");
      return;
    }

    const myAccounts = this.$msalInstance.getAllAccounts();
    const account = myAccounts[0];

    const request = {
      account,
      scopes: [REQUEST_SCOPE],
    };

    try {
      const tokenResponse =
        await this.$msalInstance.acquireTokenSilent(request);
      this.onTokenSuccess();
      return tokenResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const tokenResponse =
          await this.$msalInstance.acquireTokenPopup(request);
        console.log(
          `Access token acquired via interactive auth ${tokenResponse.accessToken}`,
        );
        this.onTokenSuccess();
      } else {
        console.error(`Token acquisition failed: ${error}`);
      }
    }
  }

  onTokenSuccess() {
    const myAccounts = this.$msalInstance.getAllAccounts();
    const account = myAccounts[0];

    this.$emitter.emit("login", account);
  }

  async setup() {
    if (!this.$store || !this.$store.state || !this.$store.state.msalConfig) {
      return;
    }
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
  }
}

export default new AccessToken();
