<template>
  <div v-if="isEditing" class="date-of-death-edit-wrapper">
    <input type="date" v-model="localDateOfDeath" @keyup.enter="update()" />
    <div class="date-of-death-edit-controls">
      <button class="button-link text-small" @click="update">Save</button>
      <button class="button-link text-small" @click="cancel">Cancel</button>
    </div>
  </div>

  <p v-else class="date-of-death-edit-wrapper">
    <span>{{ localDateOfDeath }}</span>
    <button v-if="noDeathSet" class="button-link text-small" @click="edit">
      + Add date of death
    </button>
    <small v-show="updateSuccess" class="success-message">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="check"
        class="svg-inline--fa fa-check fa-w-16"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        ></path>
      </svg>
      Updated
    </small>
    <button
      v-if="!noDeathSet"
      id="date-of-death-remove-btn"
      class="button-link text-small"
      @click="remove"
    >
      Remove date of death
    </button>
  </p>
</template>

<script>
import AccessTokenService from "@/services/AccessToken.service";
import { formatDate } from "./../utils/DateUtils.js";

const SUCCESS_MESSAGE_DISPLAY_TIME_MS = 2000;

export default {
  props: {
    dateOfDeath: {
      type: String,
      required: false,
    },
    sourceSystemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      localDateOfDeath: this.formatDate(this.dateOfDeath),
      originalDateOfDeath: this.localDateOfDeath,
      noDeathSet: this.dateOfDeath === null,
      updateSuccess: false,
    };
  },
  watch: {
    localDateOfDeath(value) {
      this.localDateOfDeath = value;
    },
  },
  methods: {
    edit() {
      this.originalDateOfDeath = this.localDateOfDeath;
      this.isEditing = true;
    },
    cancel() {
      this.isEditing = false;
      this.localDateOfDeath = this.originalDateOfDeath;
    },
    async update() {
      const url = `./api/me/dateOfDeath/${this.sourceSystemId}`;
      const bearer = "Bearer " + (await AccessTokenService.getAccessToken());

      const data = {
        dateOfDeath: this.localDateOfDeath,
      };

      fetch(url, {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            this.updateSuccess = true;
            this.isEditing = false;
            this.noDeathSet = false;
            this.localDateOfDeath = this.formatDate(this.localDateOfDeath);

            setTimeout(() => {
              this.updateSuccess = false;
            }, SUCCESS_MESSAGE_DISPLAY_TIME_MS);
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async remove() {
      this.localDateOfDeath = null;
      const url = `./api/me/removeDateOfDeath/${this.sourceSystemId}`;
      const bearer = "Bearer " + (await AccessTokenService.getAccessToken());

      fetch(url, {
        method: "PUT",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            this.updateSuccess = true;
            this.isEditing = false;
            this.noDeathSet = true;

            setTimeout(() => {
              this.updateSuccess = false;
            }, SUCCESS_MESSAGE_DISPLAY_TIME_MS);
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate,
  },
};
</script>

<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

.date-of-death-edit-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;

  input {
    margin-right: $spacer-2;
  }

  span {
    font-weight: 600;

    .theme-dark & {
      font-weight: 400;
    }
  }
}

.success-message {
  align-items: center;
  animation: FADE_OUT 0.5s 1.5s ease-out forwards;
  color: var(--success);
  display: flex;
  font-weight: 400;
  margin-left: $spacer;

  svg {
    margin-right: $spacer-1;
    width: 1em;
  }
}

.date-of-death-edit-controls {
  display: grid;
  grid-gap: 0 $spacer-3;
  grid-template-columns: auto auto;
  margin-left: auto;
}

#date-of-death-remove-btn {
  margin-left: auto;
}
</style>
