<template>
  <div>
    <button
      class="button-link text-small"
      v-if="!showInput"
      @click="onShowInput"
    >
      + Add mobile number
    </button>
    <div v-else class="add-mobile-wrapper">
      <input
        type="tel"
        v-model="mobileNumber"
        :disabled="isAdding"
        @keyup.enter="add()"
        ref="telInput"
        :class="invalid ? 'invalid' : ''"
      />
      <button @click="add" class="button-small">Add</button>
      <button @click="cancel" class="button-link text-small">Cancel</button>
    </div>
  </div>
</template>

<script>
import { UK_MOBILE_NUMBER_REGEX } from "./../constants";
import AccessTokenService from "@/services/AccessToken.service";

export default {
  name: "AppDashboardAddMobile",
  props: {
    sourceSystemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isAdding: false,
      mobileNumber: "",
      showInput: false,
      invalid: false,
    };
  },
  watch: {
    mobileNumber(value) {
      this.mobileNumber = value;
      this.validateMobileNumber(value);
    },
  },
  methods: {
    validateMobileNumber(value) {
      if (UK_MOBILE_NUMBER_REGEX.test(value)) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    },
    onShowInput() {
      this.showInput = true;

      this.$nextTick(() => this.$refs.telInput.focus());
    },
    async add() {
      if (this.invalid) return;
      this.isAdding = true;

      const bearer = "Bearer " + (await AccessTokenService.getAccessToken());

      fetch(`./api/me/contact`, {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ContactTypeId: 1,
          Line1: this.mobileNumber,
          IsArchived: this.isArchived,
          IsOptedOut: this.isOptedOut,
          SourceSystemId: this.sourceSystemId,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.isAdding = false;

            throw new Error("Something went wrong");
          }
        })
        .then((responseJson) => {
          this.addSuccess = true;
          this.isAdding = false;

          this.$emitter.emit("mobileAdded", responseJson);
          this.mobileNumber = "";
          this.showInput = false;
        })
        .catch((error) => {
          this.isAdding = false;

          console.log(error);
        });
    },
    cancel() {
      this.showInput = false;
      this.mobileNumber = "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

.add-mobile-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: $spacer-2 $spacer;

  input {
    grid-column: 1 / -1;
  }
}
</style>
